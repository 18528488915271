<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />

    <b-card v-else>
      <template v-slot:header>
        <h5>Sub Kategori FPP</h5>
      </template>
      <div
        class="search-wrapper d-flex align-items-center justify-content-between"
      >
        <div class="d-flex search-input-wrapper">
          <b-form-group class="form-inline form-group-wrapper mb-3">
            <b-form-input
              type="text"
              class="mr-2"
              v-model="filter.search"
              placeholder="Pencarian..."
            ></b-form-input>

            <b-button class="mr-2" variant="success" @click="getSubKategoriFPP"
              ><i class="fa fa-search"></i> Cari</b-button
            >

            <b-button variant="primary" @click="onResetFilter"
              ><i class="fa fa-undo"></i> Atur Ulang Pencarian</b-button
            >
          </b-form-group>
        </div>
        <div>
          <b-button
            class="mb-3 mr-2"
            variant="pgiBtn"
            name="subKategoriFPPAddBtn"
            @click="$bvModal.show('m-create-sub-kategori-fpp')"
            ><i class="fa fa-plus fs-6"></i> Tambah Data</b-button
          >
        </div>
      </div>

      <!-- Table -->
      <b-table
        bordered
        hover
        show-empty
        :busy="isBusy"
        :items="subKategoriFPP"
        :fields="fields"
        :perPage="perPage"
        responsive
        class="mb-3"
      >
        <template #empty="scope"> Data tidak ditemukan </template>

        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner
              small
              class="align-middle"
              variant="secondary"
            ></b-spinner>
            <span class="font-weight-bold text-secondary">
              Harap tunggu...</span
            >
          </div>
        </template>

        <template v-slot:cell(sla)="row"> {{ row.item.sla }} Hari </template>

        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-button
              size="sm"
              class="mr-1 btn btn-sm btn-warning"
              v-b-tooltip.hover
              title="Edit"
              name="btnEditSubKategoriFPP"
              @click="
                showModalEdit(
                  `${row.item.id}`,
                  `${row.item.id_kategori_jenis}`,
                  `${row.item.name}`,
                  `${row.item.sla}`
                )
              "
            >
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button
              size="sm"
              class="ml-1 btn btn-sm btn-danger"
              v-b-tooltip.hover
              title="Delete"
              name="btnDeleteSubKategoriFPP"
              @click="
                showSweetAlertDelete(`${row.item.id}`, `${row.item.name}`)
              "
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </div>
        </template>
      </b-table>

      <div
        class="pagination-wrapper d-flex align-items-center justify-content-between"
      >
        <b-input-group prepend="Menampilkan" class="mt-3 font-weight-bold">
          <b-input-group-append>
            <b-input-group-text class="bg-white">
              <strong class="mr-1">{{ totalViewText }}</strong>
              <span class="mr-1">dari</span>
              <strong>{{ rows }}</strong>
            </b-input-group-text>
            <b-input-group-text>entri</b-input-group-text>
          </b-input-group-append>
        </b-input-group>

        <b-pagination
          class="mb-0"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          @input="onPageChange"
        ></b-pagination>
      </div>
    </b-card>

    <!-- Modal Create -->
    <b-modal
      id="m-create-sub-kategori-fpp"
      class="modal-lg"
      title="Sub Kategori FPP"
      ref="my-modal-sub-kategori-fpp"
      @hidden="hideModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <b-form @submit.prevent="onConfirm">
        <b-form-group
          id="fg-create-sub-kategori-fpp"
          label="Sub Kategori FPP"
          label-for="input-sub-kategori-fpp"
          class="required"
        >
          <b-form-input
            id="input-sub-kategori-fpp"
            type="text"
            v-model.trim="form.name"
            placeholder="Masukkan sub kategori"
            @input="checkSubKategoriCreate"
          ></b-form-input>
          <span
            v-if="!$v.form.name.required && $v.form.name.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <b-form-group
          id="fg-create-kategori-fpp"
          label="Kategori Jenis"
          label-for="select-kategori-fpp"
          class="required"
        >
          <b-form-select
            v-model="form.id_kategori_jenis"
            size="md"
            :plain="false"
            @change="checkSubKategoriCreate"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >Pilih Kategori</b-form-select-option
              >
            </template>
            <b-form-select-option
              v-for="option in optionsKategoriJenis"
              v-bind:key="option.id"
              v-bind:value="option.id"
            >
              {{ option.name }}
            </b-form-select-option>
          </b-form-select>
          <span
            v-if="
              !$v.form.id_kategori_jenis.required &&
              $v.form.id_kategori_jenis.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <b-form-group
          id="fg-create-kategori-sla-fpp"
          label="SLA (Service Level Agreement)"
          label-for="input-kategori-sla-fpp"
          class="required"
        >
          <b-form-input
            id="input-kategori-sla-fpp"
            type="number"
            v-model.trim="form.sla"
            placeholder="Masukkan SLA"
          ></b-form-input>
          <small
            v-if="!$v.form.sla.required && $v.form.sla.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</small
          >
        </b-form-group>

        <template v-if="checkSubCat">
          <small class="text-danger"
            >Sub Kategori FPP dan Kategori Jenis sudah digunakan!</small
          >
        </template>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit" :disabled="checkSubCat"
            ><i class="fa fa-save"></i> Simpan</b-button
          >
          <b-button class="ml-2" variant="dark" @click="hideModal"
            ><i class="fa fa-times"></i> Batal</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Edit -->
    <b-modal
      id="m-edit-sub-kategori-fpp"
      class="modal-lg"
      title="Ubah Sub Kategori FPP"
      ref="my-modal-sub-kategori-fpp"
      @hidden="hideModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <b-form @submit.prevent="onConfirmEdit">
        <b-form-group
          id="fg-edit-sub-kategori-fpp"
          label="Sub Kategori"
          label-for="edit-sub-kategori-fpp"
          class="required"
        >
          <b-form-input
            id="edit-sub-kategori-fpp"
            type="text"
            v-model="formEdit.name"
            @input="checkSubKategoriUpdate"
          ></b-form-input>
          <span
            v-if="!$v.formEdit.name.required && $v.formEdit.name.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <b-form-group
          id="fg-edit-kategori-fpp"
          label="Kategori Jenis"
          label-for="select-kategori-fpp"
          class="required"
        >
          <b-form-select
            v-model="formEdit.id_kategori_jenis"
            size="md"
            :plain="false"
            @change="checkSubKategoriUpdate"
          >
            <b-form-select-option
              v-for="option in optionsKategoriJenis"
              v-bind:key="option.id"
              v-bind:value="option.id"
            >
              {{ option.name }}
            </b-form-select-option>
          </b-form-select>
          <span
            v-if="
              !$v.formEdit.id_kategori_jenis.required &&
              $v.formEdit.id_kategori_jenis.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <b-form-group
          id="fg-edit-kategori-sla-fpp"
          label="SLA (Service Level Agreement)"
          label-for="edit-kategori-sla-fpp"
          class="required"
        >
          <b-form-input
            id="edit-kategori-sla-fpp"
            type="number"
            v-model="formEdit.sla"
            placeholder="Masukkan SLA"
          ></b-form-input>
          <span
            v-if="!$v.formEdit.sla.required && $v.formEdit.sla.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <template v-if="checkSubCat">
          <small class="text-danger"
            >Sub Kategori FPP dan Kategori Jenis sudah digunakan!</small
          >
        </template>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit" :disabled="checkSubCat"
            ><i class="fa fa-save"></i> Simpan Perubahan</b-button
          >
          <b-button class="ml-2" variant="dark" @click="hideModal"
            ><i class="fa fa-times"></i> Batal</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      filter: {
        search: null,
      },
      form: {
        id_kategori_jenis: null,
        name: null,
        sla: null,
      },
      formEdit: {
        id: null,
        id_kategori_jenis: null,
        name: null,
        sla: null,
      },
      formDelete: {
        activeName: null,
        activeId: null,
      },
      fields: [
        {
          key: "name",
          label: "Sub Kategori",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "name_category",
          label: "Kategori Jenis",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "sla",
          label: "SLA (Service Level Agreement)",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      subKategoriFPP: [],
      subKategoriFPPAll: [],
      optionsKategoriJenis: [],
      kategoriJenis: [],
      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      query: "",
      url: "",
      loading: false,
      checkSubCat: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      id_kategori_jenis: {
        required,
      },
      sla: {
        required,
      },
    },

    formEdit: {
      name: {
        required,
      },
      id_kategori_jenis: {
        required,
      },
      sla: {
        required,
      },
    },
  },

  methods: {
    //----------------- Initial Function -----------------//
    init() {
      this.initStatus = 0;
      let getAllData = "";

      // Get Kategori Jenis
      getAllData = this.$axios
        .get("api/internal-memo/kategori-jenis/all")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (let value of datas) {
              this.optionsKategoriJenis.push({
                id: value.id,
                name: value.name,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      // Sub Kategori FPP
      getAllData = this.$axios
        .get("api/internal-memo/kategori-sub")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (let data of datas.data) {
              let check = this.optionsKategoriJenis.find(
                (item) => item.id == data.id_kategori_jenis
              );

              this.subKategoriFPP.push({
                id: data.id,
                name: data.name,
                name_category: check ? check.name : "-",
                id_kategori_jenis: data.id_kategori_jenis,
                sla: data.sla,
              });
            }

            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = datas.from;
            this.limit = datas.to;

            this.updateTotalItem(datas.total);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      getAllData = this.$axios
        .get("api/internal-memo/kategori-sub/all")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (let data of datas) {
              this.subKategoriFPPAll.push({
                id: data.id,
                name: data.name,
                id_kategori_jenis: data.id_kategori_jenis,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      Promise.all([getAllData])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
          this.toggleBusy();
        });
    },

    //----------------- Pagination, Sorting, dan Filtering Function -----------------//
    onPageChange() {
      if (this.loading == false) {
        this.page = this.currentPage;
        this.query = "?page=" + this.page;
        this.getSubKategoriFPP();
      }
    },

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
      } else {
        this.isBusy = true;
      }
    },

    updateTotalItem(total) {
      this.perPage = this.size;
      this.rows = total;

      const resultFrom = this.from == null ? 0 : this.from;
      const resultLimit = this.limit == null ? 0 : this.limit;

      this.totalViewText = resultFrom + " - " + resultLimit;
    },

    //-----------------  Callback Function -----------------//
    getSubKategoriFPP: function () {
      this.toggleBusy();

      if (this.filter.search) {
        this.query = "?search=" + this.filter.search;
      } else {
        this.query = "?page=" + this.page;
      }

      this.$axios
        .get("api/internal-memo/kategori-sub" + this.query)
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;
            this.toggleBusy();
            this.subKategoriFPP = [];

            for (let data of datas.data) {
              let check = this.optionsKategoriJenis.find(
                (item) => item.id == data.id_kategori_jenis
              );

              this.subKategoriFPP.push({
                id: data.id,
                name: data.name,
                name_category: check ? check.name : "-",
                id_kategori_jenis: data.id_kategori_jenis,
                sla: data.sla,
              });
            }

            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = datas.from;
            this.limit = datas.to;

            this.updateTotalItem(datas.total);
          }
        })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
        });
    },

    onResetFilter: function () {
      this.filter = {
        search: null,
      };

      this.query = "";
      this.getSubKategoriFPP();
    },

    //-----------------  Reset Function -----------------//
    resetForm: function () {
      this.form = {
        id_kategori_jenis: null,
        name: null,
        sla: null,
      };

      this.formEdit = {
        id: null,
        id_kategori_jenis: null,
        name: null,
        sla: null,
      };

      this.formDelete = {
        activeName: null,
        activeId: null,
      };

      this.checkSubCat = false;

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    //-----------------  Hide Modal Function -----------------//
    hideModal: function () {
      this.$refs["my-modal-sub-kategori-fpp"].hide();
      this.resetForm();
    },

    checkSubKategoriCreate: function () {
      let findSubCat = this.subKategoriFPPAll.find(
        (item) =>
          item.name
            .toLowerCase()
            .split(" ")
            .join("")
            .match(this.form.name.toLowerCase().split(" ").join("")) &&
          item.id_kategori_jenis == this.form.id_kategori_jenis
      );

      if (this.form.name && this.form.id_kategori_jenis) {
        if (findSubCat) {
          this.checkSubCat = true;
        } else {
          this.checkSubCat = false;
        }
      } else {
        this.checkSubCat = false;
      }
    },

    checkSubKategoriUpdate: function () {
      let findSubCat = this.subKategoriFPPAll.find(
        (item) =>
          item.name
            .toLowerCase()
            .split(" ")
            .join("")
            .match(this.formEdit.name.toLowerCase().split(" ").join("")) &&
          item.id_kategori_jenis == this.formEdit.id_kategori_jenis
      );

      if (this.formEdit.name && this.formEdit.id_kategori_jenis) {
        if (findSubCat) {
          this.checkSubCat = true;
        } else {
          this.checkSubCat = false;
        }
      } else {
        this.checkSubCat = false;
      }
    },

    //-----------------  Confirm Create Function -----------------//
    onConfirm: function () {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      } else {
        this.showSweetAlertCreate();
        this.$bvModal.hide("m-create-sub-kategori-fpp");
      }
    },

    //-----------------  Confirm Edit Function -----------------//
    onConfirmEdit: function () {
      this.$v.formEdit.$touch();

      if (this.$v.formEdit.$anyError) {
        return;
      } else {
        this.showSweetAlertEdit();
        this.$bvModal.hide("m-edit-sub-kategori-fpp");
      }
    },

    //-----------------  Create Function -----------------//
    showSweetAlertCreate: function () {
      let form = {
        id_kategori_jenis: this.form.id_kategori_jenis,
        name: this.form.name,
        sla: this.form.sla,
      };

      this.$swal({
        title: "Konfirmasi",
        html: "<strong>Konfirmasi</strong><span>, untuk melanjutkan penyimpanan data sub kategori.</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Penyimpanan data sub kategori sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post("/api/internal-memo/kategori-sub", form)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Sub Kategori berhasil disimpan.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                });

                this.resetForm();
                this.getSubKategoriFPP();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: "Gagal",
                text: "Sub Kategori gagal disimpan.",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });
              this.resetForm();
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });
          this.resetForm();
        }
      });
    },

    //-----------------  Edit Function -----------------//
    showModalEdit: function (id, id_kategori_jenis, name, sla) {
      this.formEdit.id = id;
      this.formEdit.id_kategori_jenis = id_kategori_jenis;
      this.formEdit.name = name;
      this.formEdit.sla = sla;

      this.$bvModal.show("m-edit-sub-kategori-fpp");
    },

    showSweetAlertEdit: function () {
      let formEdit = {
        id: this.formEdit.id,
        id_kategori_jenis: this.formEdit.id_kategori_jenis,
        name: this.formEdit.name,
        sla: this.formEdit.sla,
      };

      this.$swal({
        title: "Konfirmasi",
        html: "<strong>Konfirmasi</strong><span>, untuk melanjutkan perubahan data sub kategori.</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Perubahan data sub kategori sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post("/api/internal-memo/kategori-sub/" + formEdit.id, formEdit)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Data sub kategori berhasil diubah.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                });

                this.resetForm();
                this.getSubKategoriFPP();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: "Gagal",
                text: "Data sub kategori gagal diubah.",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
              this.resetForm();
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });
          this.resetForm();
        }
      });
    },

    //-----------------  Delete Function -----------------//
    showSweetAlertDelete: function (id, name) {
      this.formDelete.activeId = id;
      this.formDelete.activeName = name;

      var jenisName = this.formDelete.activeName;
      var contentJenis = document.createElement("div");
      contentJenis.innerHTML =
        '<strong>Konfirmasi</strong>, untuk melanjutkan proses penghapusan data sub kategori <strong>"' +
        jenisName +
        '"</strong>?';

      this.$swal({
        title: "Konfirmasi",
        html: contentJenis,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya. Hapus!",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Penghapusan data sub kategori sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .delete(
              "api/internal-memo/kategori-sub/delete/" +
                this.formDelete.activeId
            )
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Data sub kategori berhasil dihapus.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                });

                this.resetForm();
                this.getSubKategoriFPP();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: "Gagal",
                text: "Data sub kategori gagal dihapus.",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });
          this.resetForm();
        }
      });
    },
  },

  //-----------------  Initial Function -----------------//
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.b-table-empty-row {
  text-align: center;
}

.table th,
.table td {
  vertical-align: middle;
}

.nameOfTheClassTh {
  text-align: center;
}

.nameOfTheClassThAction {
  width: 10%;
  text-align: center;
}

.nameOfTheClassTd {
  text-align: center;
}
</style>
